/*----------------------------------------------------*/
/*--------------------[CASE STUDIES]------------------*/
/*----------------------------------------------------*/

@font-face {
  font-family: 'Glyphicons Halflings';

  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-chevron-left:before {
  content: "\e079";
}
.glyphicon-chevron-right:before {
  content: "\e080";
}
.glyphicon-align-justify:before {
  content: "\e055";
}

#case-studies {
  section#casestudy {
    padding-top: $font-size-base * 1.529411764;
    padding-bottom: $font-size-base * 2.058823529; 
  }

  .service-icon-container {
    display: block;
    fill: $jellybean;
    margin: 1em auto 1.25em;
    #icon-opportunity-assessment {
      max-width: $font-size-base * 4.411764705;    
    }
    #icon-asset-strategy {
      max-width: $font-size-base * 9.3;    
    }
    #icon-asset-search {
      max-width: $font-size-base * 4.6;    
    }
    #icon-chess-piece {
      max-width: $font-size-base * 4.2;    
    }
    #icon-planning-checklist {
      max-width: $font-size-base * 3.8;    
    }
  }
  p.category {
    color: $jellybean;
    font-weight: $font-weight-bold;
    font-size: $font-size-base * 1.17640588;
    line-height: $font-size-base * 1.529411764;
    padding-bottom: $font-size-base;
    span {
      display: block;
      text-transform: uppercase;
      font-size: $font-size-base * 1.058823529;
    }
  }
  h1 {
    color: $firebush;
    font-size: $font-size-base * 1.411764705;
    line-height: $font-size-base * 2.117647058;
    font-weight: $font-weight-bold;
    text-transform: none;
  }

  h2 {
    color: $jellybean;
    }
  h3, h4 {
    color: $jellybean;
    text-transform: uppercase;
  }
  h3.subheader {
    margin: 0 0 $font-size-base * 4.058823529;
    font-size: $font-size-base * 1.17640588; 
    text-transform: none;
  }
  .titlecontainer {
    margin-bottom: .5em;
  }
  h4 {
    font-size: 1rem;
    margin-bottom: 1em;
  }
  .situation {
    padding: 2.25em  2em 1.5em;
    position: relative;

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: $jellybean;
      border-width: 30px;
      margin-left: -30px;
    }
  }
  .action {
    padding: 3.25em 0 0;
    position: relative;
  }
  .action, .impact {
    font-size: 1rem;
  }

  ul.text-white {
    padding-left:1.2em; 
    margin-left: 0; 

    li {
      color: $firebush;
      ul li {
        color: $firebush;
      }
    }
    span {
      color: white;
    }

  }

  .badge {
    font-size: 1em;
    line-height: 1;
    border-radius: 50%;
    min-width: 1.5em;
    margin-bottom: .5em;
  }

  .divider .row .col {
    width: 100%;
    border-bottom: 2px solid $jellybean;
    margin: 3rem 0;

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: $jellybean;
      border-width: 30px;
      margin-left: -30px;
    }
  }

  .screenshot-container {
    padding-top:  $font-size-base * 4;
    padding-bottom: 3em;
  }

  .card.screenshots {
    border: none;
    margin-bottom: 3em;
    height: auto;

    img {
      height: auto;
      width: 100%
    }
  }
}
/* smaller font for the five columns */
@media screen and (min-width: 1201px) {

  h3.nativesize {
    font-size: 1rem;
  }
  ul.nativesize li {
    line-height: 1.35em;
    margin-bottom: .5em;
    font-size: 1rem;
  }

  div.titlecontainer {
    min-height: 3em;
    width: 100%;
    position: relative;
    h3 { 
      position: absolute;
      bottom: 0;
      &.centered {
        left: 50%;
        transform: translateX(-50%); 
      }
    }
  } 
} 



/* navigation at bottom of case-studies */
/* also at bottom of Pulses             */
/* WARNING changes here will change pulse navigation */
/* One style for next and previous links below applies only to pulse nave */

nav.casestudynav {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  margin-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

nav.casestudynav span.glyphicon {
  color: $tradewind;
  margin: 0 6px; }

nav.casestudynav a:link, nav.casestudynav a:visited {
  color: inherit;
  text-decoration: none; }

nav.casestudynav a:hover, nav.casestudynav a:active,
nav.casestudynav a:focus {
  background-color: transparent;
  color: #0b3947;
  text-decoration: none; 
}

nav.casestudynav.pulsenav a.disabled {
  color: lighten( $fiord, 30% );
}

nav.casestudynav.pulsenav a.disabled span {
  color: lighten( $fiord, 30% );
}

@media screen and (max-width: 767px) {
  nav.casestudynav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  nav.casestudynav div {
    padding: 17px 0; }
  nav.casestudynav div:first-of-type {
    margin-right: 6px; }
  nav.casestudynav div:last-of-type {
    margin-left: 6px; }
  nav.casestudynav span.glyphicon {
    margin: 0; } }




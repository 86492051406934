/*----------------------------------------------------*/
/*----------------------[HEADERS]---------------------*/
/*----------------------------------------------------*/
/* revised Dec. 2023 for podlove player */
/* revosed Mar. 2024 to add H1 to home page */

.jumbotron {
  border-radius: 0;
}

#bsjumbotron {
  background-image: url("../_images/headers/abstract-background-mobile.jpg");
  background-position: center center;
  background-size: cover;
  margin-bottom: 0; }

#about #bsjumbotron {
  background-image: url("../_images/headers/about.jpg");
  background-position: center 30%; }

/* podcast home header, and episodes pre-podlove player */
#podcast #bsjumbotron {
  background-image: linear-gradient(135deg, rgba(22,40,62,1) 20%, rgba(88,179,174,1) 100%);
}
@media screen and (min-width: 992px) {
  #podcast #bsjumbotron {
    background-image: url("../_images/chrome/ekg.png"), url("../_images/chrome/molecules-top-right.png"), linear-gradient(135deg, rgba(22,40,62,1) 20%, rgba(88,179,174,1) 100%);
    background-size: 20%, auto 70%, 100%;
    background-position: -25% 30%, right top;
    background-repeat: no-repeat;
  }
}
@media screen and (min-width: 1460px) {
  #podcast #bsjumbotron {
    background-size: 20%, auto 80%, 100% ;
    background-position: -12% 20%, right top;
  }
}
@media screen and (min-width: 1560px) {
  #podcast #bsjumbotron {
    background-size: 20%, auto 100%, 100% ;
    background-position: -7% 20%, right top;
  }
}

/* podcast episode headers with podlove player */
#podcast.episode #bsjumbotron {
  background-image: linear-gradient(135deg, rgba(22,40,62,1) 10%, rgba(88,179,174,1) 100%);
}
@media screen and (min-width: 992px) {
  #podcast.episode #bsjumbotron {
    background-image: url("../_images/chrome/ekg.png"), url("../_images/chrome/molecules-top-right.png"), linear-gradient(160deg, rgba(22,40,62,1) 5%, rgba(88,179,174,1) 100%);
    background-size: 20%, auto 430px, 100%;
    background-position: -12% 56px, right top, left top;
    background-repeat: no-repeat;
  }
}
@media screen and (min-width: 1460px) {
  #podcast.episode #bsjumbotron {
    background-size: 20%, auto 430px, 100% ;
    background-position: -7% 56px, right top, left top;
  }
}
@media screen and (min-width: 1560px) {
  #podcast.episode #bsjumbotron {
    background-size: 20%, auto 430px, 100% ;
    background-position: 0 56px, right top, left top;
  }
}

/* team member, services, resources, case-studies, news, careers, and candidates form version */
/* 
 * due to pages moving from section to section
 * body class has been added to fine-tune header image assignment
 */
#team-members #bsjumbotron, #careers #bsjumbotron, #candidatesform #bsjumbotron {
  background-image: url("../_images/headers/abstract-background-mobile.jpg"); }

#services #bsjumbotron, #resources.case-studies #bsjumbotron,
#case-studies.services #bsjumbotron {
  background-image: url("../_images/headers/services.jpg");
  background-position: center center; }

#resources #bsjumbotron {
  background-image: url("../_images/headers/resources.jpg");
  background-position: center center; }

#contact #bsjumbotron {
  background-image: url("../_images/headers/contact-global.jpg");
  background-position: 0 0;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-color: #121818;
}
#news #bsjumbotron {
  background-image: url("../_images/headers/news.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #121818;
}
#news.pulses #bsjumbotron {
  background-image: url("../_images/headers/oncology-pulse-section-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #121818;
}
/* subsection header on Careers page */
#life-at-bluestar .jumbotron {
  background-image: url("../_images/headers/life-at-bluestar-header-1200x236.jpg");
  background-position: center;
  background-size: auto 100% ;
  background-repeat: no-repeat;
  background-color: #4b7a91;
}
@media screen and (min-width: 1200px) {
  #life-at-bluestar .jumbotron {
    background-size: 100% auto ;
  }
}
/* subsection header on News page for Oncology Pulse */
#pulse .jumbotron {
  background-image: url("../_images/headers/oncology-pulse-section-banner.jpg");
  background-position: center;
  background-size: cover ;
  background-repeat: no-repeat;
  background-color: $gallery;
}
@media screen and (min-width: 1200px) {
  #life-at-bluestar .jumbotron {
    background-size: 100% auto ;
  }
}

#bsjumbotron #title {
  padding: 1% 0 1% 0;
  color: #ffffff; }

.jumbotron #title h1 {
  font-size: $font-size-base * 2.76471;  // 47px
  line-height: $font-size-base * 3.52941; // 60px
  font-weight: 400;
  margin: 0;
  color: #ffffff;
  text-transform: none;
  text-shadow: 1px 1px 3px #445363;
  }

#bsjumbotron #title p.h1 {
  color: #ffffff;
  font-weight: 400;
  font-size: $font-size-base * 2.76471;  // 47px
  line-height: $font-size-base * 3.52941; // 60px
  letter-spacing: normal;
  margin: 0;
  padding: 0; 
  }

#team-members #bsjumbotron #title, #careers #bsjumbotron #title,
#candidatesform #bsjumbotron #title {
  padding: 1% 0 1% 0;
  color: #ffffff; }

#team-members #bsjumbotron #title p, #careers #bsjumbotron #title p,
#candidatesform #bsjumbotron #title p {
  color: #ffffff;
  font-weight: 400;
  font-size: $font-size-base * 2.76471;  // 47px
  line-height: $font-size-base * 3.52941; // 60px
  letter-spacing: normal;
  margin: 0;
  padding: 0; }

@media screen and (max-width: 991px) {
 
  #home #headerimage {
    display: none !important; }
  #home #mobile-title {
    display: block;
    padding: 90px 0;
    background-image: url("../_images/headers/abstract-background-mobile.jpg"); }
  #home #mobile-title .title::before,
  #home #mobile-title .title::after,
  #home #mobile-title h1.title {
    padding: 0 20px;
    color: #ffffff;
    font-size: 32px;
    line-height: 36px;
    display: block; 
    text-transform:none;
    font-weight: $font-weight-bold; } }

@media screen and (max-width: 1180px) {
  #contact #bsjumbotron {
    background-position: center center;
    background-size: auto 100%;
    } }

@media screen and (min-width: 1400px) {
  #contact #bsjumbotron {
    background-position: 30% 50%;
    background-position: center center;
    background-size: auto 100%;
     } }

@media screen and (min-width: 992px) {
  #home #mobile-title {
    display: none !important; } }

@media screen and (max-width: 767px) {
  #home #mobile-title {
    display: block; }
}
/*----------------------------------------------------*/
/*---------------------[NEWS SECTION]-----------------*/
/*----------------------------------------------------*/

#news section#company-news,
#news section#conferences { 

  .not-last::after {
    content: ' | ';
  }

  .expander-container {

    text-align: center;

    p {
      margin: 0.5em;
    }

    a.expander  {
      font-weight: $font-weight-bold;
      /* if background white */
      color: #c8c8c8; 
      /* color: $fiord; */
      padding: $font-size-base $font-size-base * 1.25;

      &:link, &:visited {
        text-decoration: none;
      }
      &:hover, &:active {
        color: $jellybean;
      }
    }
  }

  .contractor-container {
    position: relative;
    margin: 0 auto;
    p {
      margin-bottom: 0;
      text-align: center;
    }

    a.expander  {
      font-weight: $font-weight-bold;
      /* if background white */
      color: #c8c8c8; 
      padding: $font-size-base $font-size-base * 1.25;

      &:link, &:visited {
        text-decoration: none;
      }
      &:hover, &:active {
        color: $jellybean;
      }
    }
  }
}













/*----------------------------------------------------*/
/*------------------[SERVICES SECTION]----------------*/
/*----------------------------------------------------*/

#services section.service { 
  padding-top: 0;
  padding-bottom: $font-size-base * 1;
  

  .last {
    padding-bottom: $font-size-base * 3;
  }

  .container {
    border-top: 2px solid $gallery;
    margin-top: $font-size-base * 2;
  }

  .container.no-top-border {
    border-top: 2px solid transparent;
    margin-top: $font-size-base * 1.7;
  }

  .container.first {
     border: none; 
     margin-top: 0;
  } 

  .header {
    position: relative; 
    margin-bottom: $font-size-base * 0;
    padding-top: $font-size-base * 2;
  }

  .lead {
    font-size: $font-size-base * 1.058823529;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size-base * 1.25;
  }

  .expander-container {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: block;

    p {
      margin-bottom: 0;
    }

    a.expander  {
      font-weight: $font-weight-bold;
      color: #c8c8c8;
      padding: $font-size-base $font-size-base * 1.25;

      &:link, &:visited {
        text-decoration: none;
      }
      &:hover, &:active {
        color: $jellybean;
      }
    }
  }

  .contractor-container {
    position: relative;
    margin: 0 auto;
    p {
      margin-bottom: 0;
      text-align: center;
    }

    a.expander  {
      font-weight: $font-weight-bold;
      color: #c8c8c8;
      padding: $font-size-base $font-size-base * 1.25;

      &:link, &:visited {
        text-decoration: none;
      }
      &:hover, &:active {
        color: $jellybean;
      }
    }
  }

  h1 {
    margin-bottom: 1em;
  }

  h2 {
    color: $jellybean;
  }

  ul { 
    padding-left:1.2em; 
    margin-left: 0; 
  }

  .service-footer {
    margin-top: $font-size-base * 1.058823529;
  }

  /* icons
   ===================================================*/
  .service-icon-container {
      display: block;
      fill: $jellybean;
      margin: 0 auto 1.25em;
      #icon-opportunity-assessment {
        max-width: $font-size-base * 4.411764705;    
      }
      #icon-asset-strategy {
        max-width: $font-size-base * 9.3;    
      }
      #icon-asset-search {
        max-width: $font-size-base * 4.6;    
      }
      #icon-chess-piece {
        max-width: $font-size-base * 4.2;    
      }
      #icon-planning-checklist {
        max-width: $font-size-base * 3.8;    
      }
  }
}











/*----------------------------------------------------*/
/*----------------------[BUTTONS]---------------------*/
/*----------------------------------------------------*/
/*
.btn-lg svg {
  transform: scale(1.75);
}*/


a.more-button {
  display: block;
  width: $font-size-base * 4.117647058;
  height: $font-size-base * 4.117647058;
  line-height: $font-size-base * 4.117647058;
  background-color: $fiord;
  border-radius: 50%;
  margin-top: $font-size-base * 2.352941176; 
}
a.more-button.jellybean {
    background-color: $jellybean;
  }

a.more-button span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; }

a.more-button::after {
  display: inline-block;
  content: url(../_images/chrome/send-icon.png);
  padding: $font-size-base * 0.529411764 0 0 $font-size-base * 1.058823529;
  margin: 0; }

a.more-button:hover {
  background-color: $jellybean !important; }

a.more-button.jellybean:hoover {
  background-color: $neptune !important;
}


.btn-custom {
  padding: $font-size-base * 0.529411764  $font-size-base * 1.75;
}
.btn-small {
  font-size: $font-size-base * 0.9;
  padding: $font-size-base * 0.2  $font-size-base * 1.0 $font-size-base * 0.2 $font-size-base * 1.2;
}

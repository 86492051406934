/*----------------------------------------------------*/
/*--------------------[NAV MENU]----------------------*/
/*----------------------------------------------------*/

// Includes the main navigation menu and the
// Back to Top scroll button that appears in the
// lower right corner (not currently used)
// Updated March 2024 to shrink logo to accommodate
//   wider navigation menu

/* Navigation - Main nav
   ===================================================*/
.navbar {
  padding: 15px 0 20px 0;
  background-color: $concrete;
  color: $navy;
  margin-bottom: 0;
  border: none;
  border-radius: 0; }

/* logo container */
.navbar-brand {
  height: auto;
  padding: 0; }

/* nav menu container */
ul.navbar-nav {
  padding-top: 18px; }

li.nav-item {
  font-size: 16px; }

ul.navbar-nav > li.nav-item > a.nav-link {
  padding: 10px 0;
  margin: 0 24px; }

ul.navbar-nav > li.nav-item:last-child > a.nav-link {
  margin-right: 0; 
  padding-right: 0;}

.navbar ul.navbar-nav > li.nav-item > a.nav-link:link,
.navbar ul.navbar-nav > li.nav-item > a.nav-link:visited {
  color: $navy;
  background-color: transparent;
  text-decoration: none;
  border-bottom: 3px solid transparent; }

.navbar ul.navbar-nav > li.nav-item > a.nav-link:hover,
.navbar ul.navbar-nav > li.nav-item > a.nav-link:active,
.navbar ul.navbar-nav > li.nav-item.dropdown.show > a.nav-link {
  background-color: transparent;
  color: $navy;
  border-bottom: 3px solid $tradewind;
  text-decoration: none; }

body#about .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="about"],
body#services .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="services"],
body.case-studies .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="services"],
body#case-studies .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="services"],
body#resources .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="resources"],
body.news .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="news"],
body.insights .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="insights"],
body#careers .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="careers"],
body#candidatesform .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="careers"],
body#contact .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="contact"],
body#podcast .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="resources"],
body#team-members .navbar ul.navbar-nav > li.nav-item > a.nav-link[data-link="about"] {
  border-bottom: 3px solid $tradewind;
}

/*
 * drop-down menu
 */

.dropdown-menu {
  background-color: $concrete;
  border: none;
  top: 60px;
  left: 0 !important;
  min-width: 120px;
  padding: 0; 
  padding-bottom: 0;
  border-radius: 0
}

.dropdown-menu a.dropdown-item  {
  font-size: 15px; 
  padding: 10px 10px 10px 12px;
  color: $navy;
  line-height: 1.75; }

.dropdown-menu.show a.dropdown-item:link,
.dropdown-menu.show a.dropdown-item:visited {
  background-color: transparent;
  color: $navy;
  text-decoration: none; }

.dropdown-menu.show a.dropdown-item:hover,
.dropdown-menu.show a.dropdown-item:active {
  background-color: $gallery;
  color: $tradewind;
  text-decoration: none; }

a.dropdown-toggle:focus {
  outline-style: none;
}

/*
 * toggle button and menu - for small screens
 */
.navbar-toggler {
  margin-top: 8px;
  background-color: $fiord; 
  border-radius: 3px;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar .navbar-toggler {
  border-color: $fiord; }

.navbar .navbar-toggler:hover,
.navbar .navbar-toggler:focus {
  background-color: $jellybean;
  border-color: $jellybean; }

/* to accommodate wider navigation menu; that is, more or wordier items */
@media screen and (min-width: 991px) and (max-width: 1151px) {
  .navbar-brand img {
    max-width: 200px;
  }
}

@media screen and (max-width: 991px) {

  .navbar-toggler {
    margin-right: .75rem;
  }

  .navbar-brand {
    padding: 6px 15px; 
  }

  .navbar-nav > li > a {
    font-weight: 600; 
  }
  .navbar ul.navbar-nav > li.nav-item > a.nav-link:link,
  .navbar ul.navbar-nav > li.nav-item > a.nav-link:visited {
    color: $navy;
    border-bottom: none;    
  }

  .navbar ul.navbar-nav > li.nav-item > a.nav-link:hover,
  .navbar ul.navbar-nav > li.nav-item > a.nav-link:active,
  .navbar ul.navbar-nav > li.nav-item.dropdown.show > a.nav-link {
    color: $tradewind;
    border-bottom: none; 
  }

  body .navbar ul.navbar-nav > li.nav-item > a.nav-link {
    border-bottom: none !important;
  }

  .dropdown-menu {
    margin-left: 2rem;
  }

  .dropdown-menu a.dropdown-item {
    font-size: 16px;
    padding: 15px 15px 16px 16px;
    margin: 0;
   }
  /* was 767 */
  a.more-button {
    margin-top: 12px; }
 }

 /* Navigation - Back to top button in lower right
   ===================================================*/
// not used in current release
/*
.glyphicon-arrow-up:before {
  content: "\e093";
}

#toTop{
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  display: none;
  z-index: 1000;
}

.btn-totop {
  color: #fff;
  background-color: #1a84ac; 
  border-color: #1b6d85;
  padding: .375rem .5rem;
  border-radius: 3px
}
.btn-totop:focus,
.btn-totop.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}
.btn-totop:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-totop:active,
.btn-totop.active {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-totop:active:hover,
.btn-totop.active:hover,
.btn-totop:active:focus,
.btn-totop.active:focus,
.btn-totop:active.focus,
.btn-totop.active.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}
.btn-totop:active,
.btn-totop.active {
  background-image: none;
}
*/
/*----------------------------------------------------*/
/*-----------------[BASIC TYPOGRAPHY]-----------------*/
/*----------------------------------------------------*/
/* March 2024 added head overrides for home page
   to make heading more hierachical                   */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

strong, b {
  font-weight: $font-weight-bold;
}

h1 {
  text-transform: uppercase;
  line-height: $font-size-base * 1.529411764;
}

#home h2 {
  font-size: calc(1.26875rem + 0.225vw);
  text-transform: uppercase;
}
#home h3 {

}

hr {
  margin: $font-size-base 0 $font-size-base * 1.25;
}

.header {
  margin-bottom: $font-size-base * 1.529411764; 
}

.lead {
  // much of this is provided by BS
  margin-bottom: $font-size-base * 2;
  /*font: {
    size: $font-size-base * 1.470588235; // 25
    weight: $font-weight-semibold;
  }*/
}

.lead-18-b {
  // base lead font size is 25
  margin-bottom: $font-size-base * 2;
  font: {
    size: $font-size-base * 1.058823529; // 25
    weight: $font-weight-bold;
  }
}

.font-weight-semibold {
  font-weight: 600;
}

p.eyebrow {
  font: {
    size: $font-size-base * .9; 
  }
  margin-bottom: 0;
}

/* Head style overrides for home page. 
   =================================================== */

/* Lists
   with colored bullets. 
   =================================================== */

ul {
  padding-left: 1.2em;
  margin-left: 0;
  li {
    margin-bottom: .5em;
  }
  &.orange-bullets li {
    color: $firebush;
    ul li {
      color: $body-color;

      &:first-child {
        margin-top: .5em;
      }
    }
  }
  span {
    color: $body-color;
  }
}

/* Links
   For basic link styles, see custom variables file 
   =================================================== */
.more-link {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  font-size: 14px
}

a.whitelink {
  color: #ffffff;
  font-size: .9em;
}

a.whitelink:link {
    color: #ffffff;
    text-decoration: none;
}

a.whitelink:visited {
    color: #ffffff;
    text-decoration: none;
}

a.whitelink:focus {
    color: #ffffff;
    text-decoration: underline;
}

a.whitelink:hover {
    color: #ffffff;
    text-decoration: underline;
}

a.whitelink:active {
    color: #ffffff;
    text-decoration: underline;
}







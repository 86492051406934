// Overwrite Bootstrap's variables
// Bootstrap defines them in node_modules/bootstrap/scss/_variables.scss
// For Bluestar website revamp, October 2020

// Color system
//
// Creative names for colors in Bluestar Palette. 
// From chir.ag/projects/name-that-color

$concrete:  #f3f3f3;     // formerly off-white
$tradewind: #5bb7b2;     // formerly turquoise
$hippyblue: #48a49f;     // slightly shaded turquoise, for improved legibility
$neptune:   #71b5b2;     // Jane's new beautiful variant of turquoise
$gallery:   #ebebeb;     // formerly gray
$fiord:     #435263;     // formerly navy for backgrounds
$jellybean: #227493;     // formerly blue
$firebush:  #ec9530;     // formerly orange
$anzac:     #e29847;     // Jane's new orange
$navy:      #445363;     // for text
$bigstone:  #16283e;     // less-gray blue for homepage intro panel
$orient:    #005c81;     // darker blue for oncology pulse newsletter
$fandango:  #ac0688;     // magenta for newsletters
$fandangoTint: #ffe7fa;
$neptuneTint: #E1EFEE;

// Adding additional theme color (ex. classes btn-fiord, bg-fiord)
$theme-colors: (
  "concrete":  $concrete,
  "tradewind": $tradewind, 
  "hippyblue": $hippyblue,    
  "neptune":   $neptune,    
  "gallery":   $gallery,     
  "fiord":     $fiord,     
  "jellybean": $jellybean,    
  "firebush":  $firebush,    
  "anzac":     $anzac,
  "navy":      $navy,
  "bigstone":  $bigstone,
  "orient":    $orient,
  'fandango':  $fandango,
  'fandangoTint': $fandangoTint,
  'neptuneTint': $neptuneTint
);

// Options 
//
// Enable global styling turned off by default

$enable-responsive-font-sizes: true;
$enable-shadows: true;
$enable-gradients: true;
$enable-rounded:   true;

// Body
//
// Settings for the `<body>` element.
$body-color: $fiord;


// Links
//
// Style anchor elements.

$link-color:                              $body-color;
$link-decoration:                         underline;
$link-hover-color:                        darken($link-color, 50%);
// $link-hover-decoration:                   underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 30% !default;

// Typography
//
// Overrides for Bootstrap defaults

$font-family-base: 'Open Sans', helvetica, arial, sans-serif;
$font-size-base: 1.0625rem;                                   // 17
$line-height-base: 1.625;
$paragraph-margin-bottom: 1.063rem;

//$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-bolder:          800;

$h1-font-size:                $font-size-base * 1.352941176;   // 17 * 1.352 = 23
$h2-font-size:                $font-size-base * 1.17640588;    // 17 * 1.17640588 = 20
$h3-font-size:                $font-size-base * 1;             // 17 
$h4-font-size:                $font-size-base * 1;
$h5-font-size:                $font-size-base * 1;
$h6-font-size:                $font-size-base * 1;

// $headings-margin-bottom:   $spacer / 2 !default;
// $headings-font-family:     null !default;
$headings-font-weight:        $font-weight-bold;
// $headings-line-height:     1.2 !default;
$headings-color:              $firebush;

$display1-size:               $font-size-base * 2.76470;     // 17 * 2.76470 = 47;
//$display2-size:             5.5rem !default;
//$display3-size:             4.5rem !default;
//$display4-size:             3.5rem !default;

$display1-weight:             400;
//$display2-weight:           300 !default;
//$display3-weight:           300 !default;
//$display4-weight:           300 !default;
//$display-line-height:       $headings-line-height !default;

$lead-font-size:            $font-size-base * 1.470588235;
$lead-font-weight:          $font-weight-semibold;



// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               .375rem;
$btn-padding-x:               2.5rem;
$btn-font-family:             null;
$btn-font-size:               $font-size-base * 1.058823529;
$btn-line-height:             $font-size-base * 1.058823529;
// $btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

/* small button */
$btn-padding-y-sm:            .375rem;
$btn-padding-x-sm:            1.5rem;
$btn-font-size-sm:            .8em;
$btn-line-height-sm:          null;

/* large button */
$btn-padding-y-lg:            $font-size-base * .75;
$btn-padding-x-lg:            $font-size-base * 5.882352941;
//$btn-padding-r-lg:            $font-size-base * 7.705882352;
$btn-font-size-lg:            $font-size-base * 1.058823529;;
$btn-line-height-lg:          $font-size-base * 1.058823529;;

/* other settings */
$btn-border-width:            0;

$btn-font-weight:             $font-weight-bold;
$btn-box-shadow:              none;
$btn-focus-width:             none;
$btn-focus-box-shadow:        none;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       none;

// $btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $font-size-base * 1.764705882;
$btn-border-radius-lg:        $font-size-base * 1.764705882;
$btn-border-radius-sm:        $font-size-base * 1.764705882;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// This variable affects the `.h-*` and `.w-*` classes.
// adding
$sizes: (
    32: 32%
);




// Changing modal-brackdrop color
//$border-radius: 15px;
//background-color: $modal-backdrop-bg;
//&.show { opacity: $modal-backdrop-opacity; 
$modal-backdrop-bg: $jellybean;
$modal-backdrop-opacity: .65;






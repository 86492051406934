/* background for careers section on About page */
#about #careers {
  background-image: url("../_images/chrome/2022-careers-section-background.jpg");  
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #4b7a91;
}

#about #careers .innercontainer {
  min-height: 600px;
}

@media screen and (min-width: 768px) {
  #about #careers .innercontainer {
    min-height: 400px;
  }
}

@media screen and (min-width: 1200px) {
  #about #careers .innercontainer {
    min-height: 400px;
  }
}
@media screen and (min-width: 1300px) {
  #about #careers .innercontainer {
    min-height: 480px;
  }
}
@media screen and (min-width: 1500px) {
  #about #careers .innercontainer {
    min-height: 520px;
  }
}

/*----------------------------------------------------*/
/*--------------------[Modals]------------------*/
/*----------------------------------------------------*/

.modal-fullscreen .modal-dialog {
    max-width: 100%;
}

@media screen and (min-width: 768px) {
  .modal-fullscreen .modal-dialog {
    max-width: 90%;
  }
}
/*----------------------------------------------------*/
/*-----------------[CANDIDATES FORM]------------------*/
/*----------------------------------------------------*/
section#candidates {
  padding-top: 0;
  color: $fiord; }

#candidatesForm .form-group {
  margin: 30px 0; 
}

#candidatesForm .form-group .form-control {
  height: 46px;
  font-size: 18px;
  line-height: 1.3333333;
  color: $fiord;
  border-color: $fiord;
  border-radius: 0; 
}

#candidatesForm .form-group input[type="file" i] {
  display: block;
  font-size: $font-size-base * 0.94117647;;
}

#candidatesForm .form-group textarea.form-control,
#candidatesForm .form-group select[multiple].form-control {
  height: auto;
  border-radius: 12px; }

#candidatesForm .form-group textarea.form-control {
  border-radius: 0; }

#candidatesForm .btn-default {
  margin: 12px 0 40px 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  border-radius: 30px;
  padding: 18px 131px 18px 100px;
  background-color: $jellybean;
  border: none; }

#candidatesForm .btn-default::after {
  content: url(../_images/chrome/send-icon.png);
  padding: 0 0 0 16px;
  margin: 0;
  height: 29px;
  position: absolute;
  top: center; }

#candidatesForm .btn-default:hover::after {
  content: url(../_images/chrome/send-icon.png);
  padding: 0 0 0 16px; }


#candidatesForm .btn-default:hover, #candidatesForm .btn-default:active {
  background-color: $jellybean; }

#candidatesForm .help-block.with-errors li {
  color: $jellybean; 
  font-weight: $font-weight-bold;
}

#candidatesform #feedback {
  color: $jellybean;
  font-size: 24px; 
}
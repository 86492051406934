/*----------------------------------------------------*/
/*---------------[Careers Page Overrides]-------------*/
/*----------------------------------------------------*/

#careers {
  h1 {
    margin-bottom: 0;
  }

  #accordion 
    .card {
      margin-bottom: $font-size-base * 2;
      border-radius: 0;
    }
    .card-header h2 a {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      text-decoration: none; 
    }
    .card-header h2 a span {
      font-weight: $font-weight-normal; 
    }

    .card-header a.accordion-toggle:after {
      font-family: 'Glyphicons Halflings';
      content: "\e114";
      float: right;
      color: $fiord;
      margin-top: -.75em; 
    }

    .card-header a.accordion-toggle.collapsed:after {
      /* symbol for "collapsed" panels */
      content: "\e080";
      padding: 0; 
    }

}
#life-at-bluestar .gallery [class*="col-"] {
  padding: 4px;
 
}

#life-at-bluestar img {
  margin-top: .75rem;
}
/*----------------------------------------------------*/
/*---------------[Homepage Animation]-----------------*/
/*----------------------------------------------------*/

/* 
 *styles for simplified version of the star flower animation 
 * 
 * /

/* containers */

svg#services-graphic {
  width: auto;
  margin-right: 4%;
  margin-left: -4%; 
}

#service-description-container {
  margin-left: 8%;
  min-height: 350px;
}

.border-rule {
  border-right: 1px solid #969696;
}


/* THE GRAPHIC */

/* star flower */
.petal {
  fill: #435464;
}

/* titles on graphic */

.service-block, .fat-arrowhead  {
  fill: #969696;
  cursor: pointer;
}

.fat-arrowhead.pointingdown {
  display: none;
}

.service-title {
  font-size: 24px;
  font-family: OpenSans-SemiBold, Open Sans;
  font-weight: 600;
  text-transform: none;
  color: #227493;
}

.service-block.active .service-title {
  fill: #227493 ;
}

.service-block.active .fat-arrowhead {
  fill: #ec9530;
}

/* TEXT DESCRIPTION */

#service-description-container {
  width: auto;
}

#service-description-container h3 {
  font-size: 20px;
  font-family: OpenSans-SemiBold, Open Sans;
  font-weight: 600;
  text-transform: none;
  color: #227493;
  text-align: left;
}

#service-description-container p {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #475362;
}
/* exception for long description */
@media screen and (min-width: 1199.98px) {
  #service-description-container #_4-description p {
    font-size: 0.95em;
    line-height: 1.6;
    margin-bottom: .6rem;
  }
}
@media screen and (min-width: 991.98px) and (max-width: 1200px) {
  #service-description-container #_4-description p {
    font-size: 0.9em;
    line-height: 1.35;
    margin-bottom: .6rem;
  }
  #service-description-container #_4-description h3 {
    font-size: 1em;
  }
}

#service-description-container a,
#service-description-container a:link, 
#service-description-container a:visited {
  text-transform: uppercase;
  font-weight: $font-weight-bolder;
  font-size: 14px;
  line-height: 26px;
  color: $firebush;
  text-decoration: none; }

#service-description-container a:hover, 
#service-description-container a:active, #service-description-container a:focus {
  text-decoration: underline; 
}

@media screen and (max-width: 991.98px) {
  svg#services-graphic {
    margin-right: 8%;
    margin-left: -8%;
    padding-bottom: 3rem; 
  }

  #service-description-container {
    margin-left: 0;
    margin-top: 2rem;
    min-height: auto;
    
    h2, h3, p {
      text-align: center;
    }

    h2 {
      margin-bottom: 1.25rem;
    }
  }

  .fat-arrowhead.pointingright {
    display: none;
  }

  .fat-arrowhead.pointingdown {
    display: inline-block;
  } 

  .border-rule {
    border-right: none;
    border-bottom: 1px solid #969696;
  }
}

@media screen and (max-width: 767.98px) {
  svg#services-graphic {
    margin-right: 0;
    margin-left: -10%;
  }
}


/*----------------------------------------------------*/
/*------------------[CONTACT PAGE]--------------------*/
/*----------------------------------------------------*/
/* Contact Form
===================================================*/
.glyphicon-book:before {
  content: "\e043";
}
.glyphicon-user:before {
  content: "\e008";
}
.glyphicon-align-left:before {
  content: "\e052";
}
.glyphicon-envelope:before {
  content: "\2709";
}
.glyphicon-pencil:before {
  content: "\270f";
}
.glyphicon-flag:before {
  content: "\e034";
}

#contactForm .form-group .form-control {
  height: 46px;
  font-size: 18px;
  line-height: 1.3333333;
  color: #ffffff;
  background-color: #6cbeba;
  border: none;
  border-radius: 34px;
  padding: 10px 15px 10px 50px; }

#contactForm .form-group textarea.form-control,
#contactForm .form-group select[multiple].form-control {
  height: auto;
  border-radius: 6px; }

/* 
 * context for for icon positioning 
 */
#contactForm .field-container {
  position: relative;
  width: 80%;
  margin: 36px auto; }

#contactForm .field-container .glyphicon {
  position: absolute;
  padding: 14px 0 0 24px;
  pointer-events: none;
  color: #8dc2d6;
  color: #b2dddb;
  font-size: 17px; }

/* icons in text field */
#contactForm .left-icon-pos .glyphicon {
  left: 0px; }

/* end icon positioning */
#contactForm .form-control:focus {
  border-color: transparent;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

#contactForm .form-control::-moz-placeholder {
  color: #fff;
  opacity: 1; }

#contactForm .form-control:-ms-input-placeholder {
  color: #fff; }

#contactForm .form-control::-webkit-input-placeholder {
  color: #fff; }

#contactForm .btn-default {
  margin: 12px 0 40px 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  border-radius: 30px;
  padding: 12px 100px;
  background-color: #0b3947;
  border: none; }

#contactForm a.btn-default:link, #contactForm a.btn-default:visited {
  color: #ffffff;
  text-decoration: none; }

#contactForm a.btn-default:hover, #contactForm a.btn-default:active {
  color: #ffffff; }

/* validation notifications */
#contactForm .help-block.with-errors {
  text-align: left; }

#contactForm .help-block.with-errors li {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #445365; }

#contactForm #msgSubmit h3,
#contactForm #msgSubmit h3 {
  margin-top: -10px;
  font-size: 16px;
  color: #ffffff; }

#contactForm #msgSubmit.h3.text-danger {
  margin-top: -10px;
  color: #445365;
  font-size: 14px; }

#contactForm #msgSubmit.h3.text-success {
  margin-top: -10px;
  color: #ffffff; }

/* Map
===================================================*/
#map-container {
  position: relative;
  margin-top: 59px;
  height: 540px;
  color: #000000;
  /* for google popup */
  background-color: #f3f3f3; }

p.map-link {
  font-size: 12px; }

.map-link a:link, .map-link a:visited {
  color: #ffffff;
  text-decoration: none; }

.map-link a:hover, .map-link a:active {
  background-color: transparent;
  color: #0b3947; }
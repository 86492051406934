/*----------------------------------------------------*/
/*---------------[RESOURCES and NEWS]-----------------*/
/*----------------------------------------------------*/

/* News Items and Case Studies list
===================================================*/
// Note: the round "more" buttons with arrows are in
// the Buttons file
// See also _news.scss

#resources, #company-news, .pulses, #conferences {
  .item {
    padding: 20px 0;
    position: relative;
    border-bottom-width: 2px;
    border-bottom-style: solid; 
    border-bottom-color: $gallery;
    &:only-of-type, &:last-child, &.no-bottom-border {
      border: none !important;
    }
    h2 {
      font-size: $font-size-base * 1.235294117;
      line-height: $font-size-base * 1.588235294;
      text-transform: none;
      font-weight: $font-weight-bold;
    }
  }
  // pulse items
  #pulse .item {
    border-bottom-color: #ffffff;
  }
  // presentation items
  #presentations .item {
    border-bottom-color: #ffffff;
  }
  // thought-pieces items
  #thought-pieces .item {
    border-bottom-color: #ffffff;
  }
  h2 {
    color: $fiord;    
  }

  // casestudy items
  #casestudies .item {
    border-bottom-color: $gallery;
    h2 {
      color: $fiord;
      span.category {
        font-size: $font-size-base * 1.058823529;
        text-transform: uppercase;
        color: $jellybean;
        display: block;
      }
    }
    &.no-bottom-border {
      border-bottom: none;
    }
    a.more-button {
      margin-top: 0;
    }    
  }

  .service-icon-container {
    display: block;
    fill: $jellybean;
    margin: 0 auto 1.25em;
    #icon-opportunity-assessment {
      max-width: $font-size-base * 4.411764705;    
    }
    #icon-asset-strategy {
      max-width: $font-size-base * 9.3;    
    }
    #icon-asset-search {
      max-width: $font-size-base * 4.6;    
    }
    #icon-chess-piece {
      max-width: $font-size-base * 4.2;    
    }
    #icon-planning-checklist {
      max-width: $font-size-base * 3.8;    
    }
  }

  @media screen and (max-width: 991px) {
    #casestudies h2 {
      text-align: center;
    }
    a.more-button {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;  
    }
    .item img {
      display: none;
    }
  }
}




/*! My very own Bootstrap 4 Theme
 *
 *  Built on top of Bootstrap 4 (https://getbootstrap.com)
 *  Built with the Bootstrap Theme Kit by HackerThemes (https://hackerthemes.com)
 *  Updated September 28. 2023;
 */
@import 'custom-variables';
@import '../node_modules/bootstrap/scss/bootstrap';

// my custom styles
@import 'typography';
@import 'layout';
@import 'navigation';
@import 'headers';
@import 'footer';
@import 'animation';
@import 'team';
@import 'services';
@import 'casestudies';
@import 'buttons';
@import 'resources-news';
@import 'news';
@import 'pulse-issues';
@import 'careers';
@import 'contact';
@import 'candidates';
@import 'about';
@import 'modals';
@import 'tables';


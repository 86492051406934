/*----------------------------------------------------*/
/*--------------------[Pulse Issues]------------------*/
/*----------------------------------------------------*/
/*
    Created May, 2024
    First applied to May Oncology Newsletter
    Revised for June CNS to widen space for complex illustrations;
    Will also apply by default to May Oncology Newsletter
    Revised for September Gene Therapy and China editions
    Revised October for Obesity issue
    Revised January 2025 for Psychedelics issue
*/

/*  Standard styles across Pulse issues
    ==================================================*/

/* 
 * Layout 
 */

@media screen and (max-width: 991.98px) {
    section#pulse-issues {
        padding-top: 0; 
    }
}

/* 
 * Typography 
 */

section#pulse-issues h1 {
    font-size: $font-size-base * 1.411764705;
    line-height: $font-size-base * 1.75;
    font-weight: $font-weight-bold;
    text-transform: none;
    color: $navy;
    text-align: center; 
}

section#pulse-issues h2 {
    font-size: $font-size-base * 1.2;
    line-height: $font-size-base * 1.75;
    font-weight: $font-weight-bold;
    text-transform: none;
    color: $navy;
    text-align: center;  
}

section#pulse-issues h3,
section#pulse-issues .image-title  {
    font-size: $font-size-base * 1.2;
    line-height: $font-size-base * 2.117647058;
    line-height: $font-size-base * 1.75;
    font-weight: $font-weight-bold;
    text-transform: none;
    color: $hippyblue;
    text-align: center;
    margin-top: 2rem;
}

section#pulse-issues h4.graphic-label{
    font-size: $font-size-base * 1.2;
    line-height: $font-size-base * 2.117647058;
    line-height: $font-size-base * 1.75;
    font-weight: $font-weight-bold;
    text-transform: none;
    text-align: center;
    margin: 0 auto 1.5rem;
}

section#pulse-issues .issue-date {
    color: $jellybean;
    font-size: $font-size-base * 1.176470;
    font-weight: $font-weight-bold;
    text-align: right;
}

section#pulse-issues hr {
    border-top: 1px solid $fiord; 
}

section#pulse-issues p.intro, section#pulse-issues ul.intro {
    font-weight: $font-weight-semibold;
    font-size: $font-size-base * 1.176470;
}

section#pulse-issues .intro li {
    font-weight: $font-weight-semibold;
}

/* 
 * Modifications - Sept 2024 Started with Gene Therapy and China issues 
 */

 body.sept2024 section#pulse-issues h1 {
    font-weight: $font-weight-bold;
    text-transform: none;
    color: $navy;
    text-align: center; 
    margin-top: 2rem;
}

@media screen and (min-width: 768px) {
    body.sept2024 section#pulse-issues h1 {
        font-size: $font-size-base * 1.6;
        line-height: $font-size-base * 2;
    }
}
@media screen and (max-width: 767.98px) {
    body.sept2024 section#pulse-issues p.intro, section#pulse-issues ul.intro {
        font-size: $font-size-base;
    }
}

/*  Modules
    ==================================================*/

/* accordion */
section#pulse-issues {

  .accordion 
    .card {
        border: none;
    }
    .card-header h4 button {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      text-decoration: none;
      text-align: center;
      color: #ffffff;
      padding-right: 1rem; 
    }

    .card-header button.accordion-toggle:after {
      font-family: 'Glyphicons Halflings';
      content: "\e114";
      float: right;
      color: #ffffff;
    }

    .card-header button.accordion-toggle.collapsed:after {
      /* symbol for "collapsed" panels */
      font-family: 'Glyphicons Halflings';
      content: "\e080";
      float: right;
      color: #ffffff;
      padding: 0; 
    }

}

@media screen and (max-width: 575.98px) {
    section#pulse-issues button.btn.btn-link.btn-block.accordion-toggle {
        padding-left: 0; 
        padding-right: 0;
    }
}



/* 
 * Takeaway - Used Sept 2024 China issue 
 */
section#pulse-issues .takeaway {
    padding: .5rem 2rem .2rem;
    border-radius: .5rem;
    border: 1px solid rgb(238, 195, 149);
    background-color: rgba(238, 195, 149, 0.35);
    font-size: 1.1em;
    font-style: italic;
    text-align: center;
    font-weight: $font-weight-semibold;
    i.fa-key {
        color: $navy;
        font-size: $font-size-base * 1.2;
        text-align: center;
    }
} 

/* 
 * STICKY SECTION HEADS - First used Sept 2024 China issue 
 */

section#pulse-issues .subsection {
    position: relative;
}
section#pulse-issues .section-title {
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 0 0 1rem 0;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 15px 10px -15px $gallery;  
    p {
        font-size: $font-size-base * 1.3;
        text-align: center;
        font-weight: $font-weight-bold; 
    }              
    span.underlined {
        border-bottom-width: 4px;
        border-bottom-style: solid;
        padding-bottom: .5rem;
        border-bottom-color: #227493;
    }
}
/* color assignments for China issue */
section#pulse-issues #research-and-development .section-title span.underlined {
        border-bottom-color: #227493;
}
section#pulse-issues #regulatory .section-title span.underlined {
        border-bottom-color: #ec9530;
}
section#pulse-issues #payers .section-title span.underlined {
        border-bottom-color: #8f3985;
}
section#pulse-issues #commercial .section-title span.underlined {
        border-bottom-color: #7fd1b9;
}
section#pulse-issues #ecosystem .section-title span.underlined {
        border-bottom-color: #445463;
}
/* color assignments for Psych issue */
section#pulse-issues #current-climate .section-title span.underlined {
    border-bottom-color: #8f3985;
}
section#pulse-issues #efficacy-potential .section-title span.underlined {
    border-bottom-color: $tradewind;
}
section#pulse-issues #safety-considerations .section-title span.underlined {
    border-bottom-color: $jellybean;
}
section#pulse-issues #commercial-outlook .section-title span.underlined {
    border-bottom-color: #b4008d;
}

/* 
 * CARDS with color-coded borders and underlined titles - First used Sept 2024 China issue, expanded for use with Jan 2025 Psych issue 
 */
section#pulse-issues .cards-categories {
    .card {
        margin-bottom: 1rem;
        background-color: transparent;
        .card-header {
            height: auto;
            border-bottom: none;
            background-color: transparent;
            .card-title { 
                font-size: $font-size-base * 1.3;
                text-align: center;
                font-weight: $font-weight-semibold;              
                span.underlined {
                    border-bottom-width: 6px;
                    border-bottom-style: solid;
                    padding-bottom: .5rem;
                }
            }
        }
        .case {
            padding-top: .5rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            p {
                padding: 0 1rem;
                flex: 1 1 auto;
            }
            p.case-number {
                font-weight: $font-weight-semibold;
                flex: 0 0 auto;
                padding: 0 0 1rem 1rem;
            }
        }
        .card-body {
            padding-top: .5rem
            p {
                padding: 0 1rem;
            }
        }
    }
    /* colors for China issue */
    .card.r-and-d, .card.r-and-d span.underlined {
        border-color: #227493;
        border-bottom-color: #227493;
    }
    .card.regulatory, .card.regulatory span.underlined {
        border-color: #ec9530;
    }
    .card.payers, .card.payers span.underlined {
        border-color: #8f3985;
    }
    .card.commercial, .card.commercial span.underlined {
        border-color: #7fd1b9;
    }
    .card.ecosystem, .card.ecosystem span.underlined {
        border-color: #445463;
    }
}
section#pulse-issues .cards-categories.card-cats2501  {
    .card .card-header {
        padding-bottom: 0;

        .card-title  {
            font-size: $font-size-base * 1.2;

            span.underlined {
                border-bottom-width: 4px;
                border-bottom-style: solid;
                padding-bottom: .3rem;
            }
        }   
    }               
    /* for psychedelics issue Jan 2025*/
    .card.curr-climate, .card.curr-climate span.underlined {
        border-color: #8f3985 ;
        border-bottom-color: #8f3985  ;
    }
    .card.efficacy-potential, .card.efficacy-potential span.underlined {
        border-color: $tradewind;
    }
    .card.safety-considerations, .card.safety-considerations span.underlined {
        border-color: $jellybean;
    }
    .card.commercial, .card.commercial span.underlined {
        border-color: #b4008d;
    }
}

/* SECTION 12 - China ///// */
section#pulse-issues .cards-china {
    .card {
        width: 21rem;
        margin: 1rem .5rem .25rem;

        .card-top {
            padding: 1rem;
            background-color: #f2f2f2;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            img {
                height: 60px;
            }
        }
        .card-header {
            height: 4rem;
            .card-title { 
                padding: .2rem;
                font-size: $font-size-base * 1.1;
                line-height: 1.2;
                color: #ffffff;
            }
        }
        .card-body {
            padding-top: .5rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            p {
                font-size: $font-size-base * .95;
                margin-bottom: .5rem;
            }
        }
    }
}

/* 
 *
 * New styles for Oct 2024 Obesity Issue 
 *
 */

 section#pulse-issues .obesity-panel {
    border-width: 1px;
    border-style: solid;
    border-left-width: 1rem;
    margin-bottom: 1rem;
    position: relative;   
}

section#pulse-issues .obesity-panel.with-numbers {
    border-width: 1px;
    border-style: solid;
    border-top-width: 1rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    p {
        font-weight: bold;
    }
    p.title {
        text-align: center;
        padding: 1rem  2rem 0;
        width: 30rem;
    }
    p.number {
        position: absolute;
        top: -2.5rem;
        left: center;
        font-size: 2rem;
        color: #ffffff;
        border-radius: 50%;
        border: 1px solid $gallery;
        line-height: 4rem;
        width: 4rem;
        text-align: center;
    } 
}
@media screen and (min-width: 768px) {
    section#pulse-issues .obesity-panel.with-numbers {
        border-left-width: 1rem;
        border-top-width: 1px;
        margin-left: 2rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        margin-right: 2rem;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        p {
            font-weight: bold;
        }
        p.title {
            text-align: center;
            padding: 1rem;
            width: 30rem;
        }
        p.number {
            position: absolute;
            top: 50%;
            margin-top: -2.5rem;
            left: -2.5rem;
            font-size: 2rem;
            color: #ffffff;
            border-radius: 50%;
            border: 1px solid $gallery;
            line-height: 4rem;
            width: 4rem;
            text-align: center;
        } 
    }

}

section#pulse-issues .obesity-panel.dosing {
    border-color: $tradewind;
    p.number {
        background-color: $tradewind;
    }
}

section#pulse-issues .obesity-panel.weight {
    border-color: $firebush;
    p.number {
        background-color: $firebush;
    }
}

section#pulse-issues .obesity-panel.muscle {
    border-color: $jellybean;
        p.number {
        background-color: $jellybean;
    }
}

section#pulse-issues .obesity-panel.with-numbers.dosing {
    border-color: $tradewind;
    p.number {
        background-color: $tradewind;
    }
}

section#pulse-issues .obesity-panel.with-numbers.weight {
    border-color: $firebush;
    p.number {
        background-color: $firebush;
    }
}

section#pulse-issues .obesity-panel.with-numbers.muscle {
    border-color: $jellybean;
        p.number {
        background-color: $jellybean;
    }
}

/* fancy flex section - section 6 
section#pulse-issues .flex-wrapper {
    display: flex;
    flex-direction: column;
    .chart-labels {
        flex: 0 1 auto; 
        .top-head {
            height: 8.94%;
            background-color: peachpuff;
        }
        .drug {
            height: 19.20%;
            background-color: whitesmoke;
        }
        .company {
            height: 16.54%;
            background-color: peachpuff;
        }
        .status {
            height: 17.21%;
            background-color: whitesmoke;
        }
        .roa {
            height: 16.86%;
            background-color: peachpuff;
        }
        .frequency {
            height: 15.23%;
            background-color: whitesmoke;
        }
        p {
            font-size: $font-size-base * .9;
            text-align: right;
            padding-top: .5rem;
            padding-right: .5rem;
            white-space: nowrap;
            margin: 0;
        }      
    }
}

@media screen and (min-width: 768px) { 
    section#pulse-issues .flex-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

} */

/* final thoughts section */
section#pulse-issues .inner-col {
    display: flex;
    flex-direction: column;
    .side-tabs {
        .side-tab {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 33%;
            min-height: 9rem;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
            .rotate {
                align-self: stretch;
                border-top-left-radius: 1rem;
                border-bottom-left-radius: 1rem;
                min-width: 2.5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            &.dosing {
                background-color: #c5e5e4;
                .rotate {
                    background-color: $tradewind;
                }
            }
            &.weight {
                background-color: #fae1c4;
                .rotate {
                    background-color: $firebush;
                }
            }
            &.muscle {
                background-color: #b2dded;
                .rotate {
                    background-color: $jellybean;
                }
            }
            p.description {
                padding: 0 1rem;
                text-align: center;
                font-size: $font-size-base * 1.1;
                font-style: italic;
                margin-bottom: 0;
                font-weight: $font-weight-semibold;
            }

            p.title {
                font-weight: $font-weight-bold;
                font-size: $font-size-base * 1.1;
                color: #ffffff;
                height: auto;
                width: auto;
                white-space: nowrap;
                transform-origin: center;
                transform: rotate(270deg);
                line-height: 1.5;
                margin-bottom: 0;
            }          
        }   
    }
    .questions {
        padding: .25rem; 
        border: 2px dashed $navy;
        .question {
            margin: 1rem;
            border: 6px solid #9dacc6;
            position: relative;
            i {
                font-size: 1.75rem;
                color: $navy;
                position: absolute;
                top: .5rem;
                left: .5rem;
            }
            p {
                text-indent: 1.75rem;
                font-weight: $font-weight-semibold;
                padding: 1rem;
                text-align: center;
                margin: 0;
            }
        }
        
    }
}
@media screen and (min-width: 768px) { 
    section#pulse-issues .inner-col {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    section#pulse-issues .side-tabs {
        width: 48%;
    }
    section#pulse-issues .questions {
        width: 51%;

    }
}


/* 
 * New styles for Sept 2024 Gene Therapy issue 
 */

/* SECTION 1 ///// */

section#pulse-issues .down-arrow-custom {
    margin: 0 auto;
    border: 1rem solid transparent;
    border-top-color: inherit;
    border-top-width: 2rem;
    border-right-width: 4rem;
    border-left-width: 4rem;
    border-bottom-width: 0;
    width: 1px;
    height: 1px;
}
section#pulse-issues .cards-custom {
    .card {
        width: 100%;
        margin-bottom: 3rem; 


        .card-header {
            height: auto;
            .card-title { 
                padding: 0 2rem;
            }
        }
        .card-body {
            padding-top: 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .card-footer {
            height: auto;
            font-size: $font-size-base * 1.1;
            font-style: italic;
            border-top:none;
        }
    }
}
@media screen and (min-width: 576px) {
    section#pulse-issues  .cards-custom  {
        .card {
            width: 75%;
            .card-header {
                .card-title {
                    padding: 0 2rem ;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {
    section#pulse-issues  .cards-custom  {
        .card {
            width: 66%;
            .card-header {
                .card-title {
                    padding: 0 2rem ;
                }
            }
        }
    }
} 
/* switches to row */
@media screen and (min-width: 992px) {
    section#pulse-issues .cards-custom {
        .card {
            width: 32%;
            .card-header {
                .card-title {
                    padding: 0 ;
                }
                min-height: 86px;
            }
            .card-footer {
                min-height: 9rem;
            }
        }
    }
}

/* SECTION 2 ///// */

section#pulse-issues .items-custom {
    .item-with-fontawesome {
        margin: 1rem .5rem 2rem;
        padding: 1.75rem 1.5rem .25rem;
        background-color: $gallery;
        position: relative;
        font-size: $font-size-base * .9;
        line-height: 1.5;
        width: 100%;
        i {
            position: absolute;
            top: -1.75rem;
            left: 50%;
            margin-left: -1.47rem;
            background-color: $gallery;
            font-size: $font-size-base * 1.4;
            padding: .75rem;
            border-radius: 50%;
        }
    }
}
@media screen and (min-width: 576px) {
    section#pulse-issues .items-custom {
        .item-with-fontawesome {
            margin: 1rem .5rem 2rem;
            padding: 1.75rem 1.5rem .25rem;
            background-color: $gallery;
            position: relative;
            font-size: $font-size-base * .9;
            line-height: 1.5;
            width: 46%;
            i {
                position: absolute;
                top: -1.75rem;
                left: 50%;
                margin-left: -1.47rem;
                background-color: $gallery;
                font-size: $font-size-base * 1.4;
                padding: .75rem;
                border-radius: 50%;
            }
        }
    }
}
@media screen and (min-width: 992px) {
    section#pulse-issues .items-custom {
        .item-with-fontawesome {
            margin: 1rem 1rem .5rem 1rem;
            padding: 1rem 1.5rem .25rem;
            background-color: $gallery;
            position: relative;
            font-size: $font-size-base * .9;
            width: auto;            
            i {
                position: absolute;
                top: -1rem;
                left: -2rem;
                margin-left: 0;
                background-color: $gallery;
                font-size: $font-size-base * 1.4;
                padding: .75rem;
                border-radius: 50%;
            }
        }
    }
}

/* SECTION 3 ///// */

/* startes as column */
section#pulse-issues .goals-trends {
    .col-1-custom{
        width: 40%;
        padding: 1rem 1rem 0 1rem ;
        border: 2px solid #ebebeb;
        border-radius: 6px;
        margin-bottom: 2rem;
    }
    .col-2-custom {
        width:100%;
    }
}
/* switches to row */
@media screen and (min-width: 768px) {
    section#pulse-issues .goals-trends {
        .col-1-custom{
            width: 19%;
            padding: 0;
            border: none;
            margin-bottom: 0;
        }
        .col-2-custom {
            width: 81%;
        }
    }
}

/* SECTION 5 ///// */
/* block items and their containers */
/* Note: block container width is controlled by BS columns in html */

section#pulse-issues .block-container-left {
    position: relative;
    margin-bottom: 3rem; 
    .arrow-container {
        position: absolute;
        top: 50%;
        margin-top: -1.5rem;
        left: 100%;
        width: 3.75rem;
        z-index: 10;
    }
    svg#icon-fat-arrow, svg#icon-fat-arrow-down { /* style assigned inside svg file icon-fat-arrow.svg */
        fill: $fiord;
    }
    .arrow-container-down {
        position: absolute;
        top: 98%;
        margin-left: -1.25rem;
        left: 50%;
        width: 3.5rem;
        z-index: 30;
    }
    .block-item {
        position: relative;
        border: 1px solid navy;
        border-radius: 6px;
        padding: 1rem 1rem 0;
        margin-bottom: .5rem;
        background-color: white;

        display: flex;
        flex-direction: column; 
        align-items: center;
        h4  {
            color: $navy;
            text-align: center;
            margin-top: .5rem;
        }
        ul {
            padding-top: .5rem;
            padding-bottom: 0;
        }
    }
}
@media screen and (min-width: 992px) {
    section#pulse-issues .block-container-left .block-item  {
        flex-direction: row; 
        align-items: center;

        h4  {
            color: $navy;
            padding-right: .5rem;
            min-width: 7rem;
            text-align: center;
            margin-top: 0;
        }
        ul {
            border-left: 1px solid $navy;
            padding-left: 1.75rem;
            padding-top: .5rem;
            padding-bottom: 0;
        }
    }
}

section#pulse-issues .block-container-right .block-item {
    border-radius: 6px;
    padding: .5rem 1rem ;
    margin-bottom: .75rem;
    background-color: $neptuneTint;
    font-style: italic;
    font-size: $font-size-base * 1.1;
    line-height: $line-height-base * .9;
}

/*  Issue-specific styles (yymm)
    ==================================================*/

/* China Sept 2024 */
section#pulse-issues #overview.globe-bkgr {
    background-image: url("../pulse-newsletters/china-2024-09/images/backgrounds/globe-flat.gif");
    background-repeat: no-repeat;
    background-size: 100% auto;
}
/* graphic with unequal halves */
/* startes as column */
section#pulse-issues .custom-flex-row2409  {
    .col-1-custom{
        width: 100%;
        padding: 0 1rem 2rem 1rem ;
    }
    .col-2-custom {
        width:80%;
    }
    .col-1-custom.orphan-drugs{
        width: 70%;
        border: 2px solid #ebebeb;
        border-radius: 6px;
        margin-bottom: 2rem;
    }
    .col-2-custom.orphan-drugs {
        width: 100%;
    }
    .col-1-custom.rccmi{
        width: 80%;
        border: none;
        padding: 1rem;
    }
    .col-2-custom.rccmi {
        width: 80%;
        padding: 1rem .5rem;
    }
    .col-1-custom.online {
        width: 80%;
        border: none;
        padding: 1rem .25rem;
    }
    .col-2-custom.online {
        width: 80%;
        padding: 1rem .25rem;
    }
}
/* switches to row */
@media screen and (min-width: 768px) {
    section#pulse-issues .custom-flex-row2409 {
        .col-1-custom{
            width: 55%;
            padding: 0;
            border: none;
            margin-bottom: 0;
        }
        .col-2-custom {
            width: 45%;
        }
        .col-1-custom.orphan-drugs{
            width: 27%;
            border: none;
        }
        .col-2-custom.orphan-drugs {
            width: 73%;
        }
        .col-1-custom.rccmi{
            width: 51.74%;
            border: none;
        }
        .col-2-custom.rccmi {
            width: 48.26%;
        }
        .col-1-custom.online{
            width: 49.763%;
            border: none;
        }
        .col-2-custom.online {
            width: 50.236%;
        }
    }
}

/* Oncology May 2024 */
section#pulse-issues div.items2405 .item {
    width: 100%;
    border-radius: 3rem 0 0 3rem;
    background-repeat: no-repeat;
    background-position: 12px center;
    padding: 1rem .25rem 1rem 0;
    margin-bottom: .75rem;
}
section#pulse-issues div.items2405 .item p {
    font-size: $font-size-base * .9;
    line-height: $font-size-base * 1.25;
    margin: 0 0 0 64px;
    padding: 0;

}
section#pulse-issues div.items2405 .adcs .item  {
    background-color: #b9eefe;
    background-image: url('/_images/icons/adc-icon.png');

}
section#pulse-issues div.items2405 .radiopharma .item {
    background-color: #ddefef;
    background-image: url('/_images/icons/radiopharmaceutical-icon.png');
}

/* Psychedelics Jan 2025 */

/* styled numbers in ordered lists */
/* based on https://blog.teamtreehouse.com/customize-ordered-lists-pseudo-element */
section#pulse-issues ol.custom-list-nums2501 {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
section#pulse-issues .custom-list-nums2501 li {
    counter-increment: step-counter; /* just sets up a name */
    padding-left: 2em;
    text-indent: -2em;
}
section#pulse-issues .custom-list-nums2501 li::before {
    content: counter(step-counter);
    font-size: 100%;
    margin-right: .45em;
    background-color: $tradewind;
    color: white;
    font-weight: bold;
    padding: .125em .5em !important;
    border-radius: 50%;
    box-shadow: rgba(0,0,0,0.15) 0px 8px 16px 0px;

}
section#pulse-issues .custom-list-nums2501.efficacy li::before {
    background-color: $tradewind;
}
section#pulse-issues .custom-list-nums2501.safety li::before {
    background-color: $jellybean;
}
/* typographic adjustment for one panel */
@media screen and (min-width: 1200px) {
    section#pulse-issues .challenges2501,
    section#pulse-issues .challenges2501 ul {
        font-size: 0.95rem;
        ul {
            line-height: 1.4
        }
    }
}
/* containers for side-by-side graphics
/* graphic with unequal halves */
/* Bootstrap does much of the flex set up. This does width and colors.
/* starts as column */
section#pulse-issues .custom-flex-row2501  {
    .col-1-custom.improvements, .col-1-custom.safety-risks {
        /* padding: 0 1rem 2rem 1rem ; */
        width: 100%;
        margin-bottom: 2rem;
    }
    .col-2-custom.efficacy-drug-spotlight {
        width: 100%;
        border: 1px solid $tradewind;
        padding: 1.5rem;
        margin-bottom: 2rem;
    }
    .col-2-custom.safety-drug-spotlight {
        width: 100%;
        border: 1px solid $jellybean;
        padding: 1.5rem;
        margin-bottom: 2rem;
    }
}
/* adjust column widths */
@media screen and (min-width: 768px) {
    section#pulse-issues .custom-flex-row2501 {
        .col-1-custom.improvements, .col-1-custom.safety-risks{
            width: 80%;
            img {
                padding-left: 1rem;
                padding-right: 2rem;
            }
        }
        .col-2-custom.efficacy-drug-spotlight, .col-2-custom.safety-drug-spotlight {
            width: 80%;
            padding: 1.5rem;
        }
    }
}
@media screen and (min-width: 992px) {
    section#pulse-issues .custom-flex-row2501 {
        .col-1-custom.improvements, .col-1-custom.safety-risks{
            width: 50%;
            img {
                padding-left: 1rem;
                padding-right: 2rem;
            }
        }
        .col-2-custom.efficacy-drug-spotlight, .col-2-custom.safety-drug-spotlight {
            width: 50%;
        }
    }
}
@media screen and (min-width: 1200px) {
    section#pulse-issues .custom-flex-row2501 {
        .col-1-custom.improvements, .col-1-custom.safety-risks{
            padding-right: 1rem;
        }
        .col-2-custom.efficacy-drug-spotlight, .col-2-custom.safety-drug-spotlight {
            padding-left: 1rem;
        }
    }
}
/* Summary */
section#pulse-issues .key-issues-table {
    width: 100%;

    border-collapse: collapse;
    tr {border-bottom: .25rem solid #ffffff}
    td {padding: 1em;}
    td.title {
        padding-left:0;
        line-height: 1.2; 
    }
    td.num, td.title {
        width: auto;
    }
    td.questions {
        width: 90%;
    }
    td.num, td.title {
        color: #ffffff;
        font-weight: $font-weight-bold;
    }
    tr:first-child {
        td.num, td.title { 
            background-color: $tradewind;
        }
        td.questions {
            background-color: #c5e5e4;
        }
    }
    tr:nth-of-type(2) {
        td.num, td.title { 
            background-color: $jellybean;
        }
        td.questions {
            background-color: #b2dded;
        }
    }
    tr:nth-of-type(3) {
        td.num, td.title { 
            background-color: $firebush;
        }
        td.questions {
            background-color: #fae1c4;
        }
    }
        tr:nth-of-type(4) {
        td.num, td.title { 
            background-color: #522d6d;
        }
        td.questions {
            background-color: #E7DBF0;
        }
    }
    tr:nth-of-type(5) {
        td.num, td.title { 
            background-color: #b4008d;
        }
        td.questions {
            background-color: #FFE7FA;
        }
    }
}
@media screen and (max-width: 767.98px){
    section#pulse-issues .key-issues-table {
        width: 100%;

        td {padding: 1em;}
        td.title {
            padding: 1.5rem 0.5em;
            line-height: 1.2;
            font-weight: $font-weight-semibold;
            vertical-align: top;
        }
        td.num {
            display: none;
        }
        td.questions ul {
            list-style-position: inside;
            padding-left: 0px;
        }
    }
}

/*  Potentially unused styles
    ==================================================*/
    section#pulse-issues col.adcs {
        padding-right: 0;
        background-color: peachpuff;
        background-color: red;
    }




/* for pulse newsletters
   horizonally scrolling table with fixed column */

.table-scroll {
	position:relative;
	max-width: 100%;
	margin:10px 0 8px;
	overflow:hidden; 
}
.table-wrap {
	width:100%;
	overflow:auto;
}
.table-scroll table {
	width:100%;
	margin:auto;
	border-collapse:collapse;
	border-spacing:0;
	font-size: .9em;
	font-weight: 600;
	line-height: 1.25;
}
.table-scroll table p {
	margin-bottom: 6px;
}
.table-scroll table strong {
	font-weight: 800;
}
.table-scroll th, .table-scroll td {
	padding: 15px 10px;
	border:1px solid #5D6D7E;
	background:#fff;
	white-space:nowrap;
	vertical-align:middle;
  text-align:center;
  font-size: .85em;
  font-weight: 600;
}
.table-scroll thead tr th {
  color: #fff;
}

.table-scroll thead tr th:nth-of-type(2) {
	background: #007ca3;
}
.table-scroll thead tr th:nth-of-type(3) {
	background:#512c6c;
}
.table-scroll thead tr th:nth-of-type(4) {
	background:#3d8e89;
}
.table-scroll thead tr th:nth-of-type(5) {
	background:#005d7a;
}
.table-scroll thead tr th:nth-of-type(6) {
	background:#b3008c;
}
.table-scroll thead tr th:nth-of-type(7) {
	background:#2c394c;
}
.table-scroll thead tr th:nth-of-type(8) {
	background:#fe9d15;
}
.table-scroll thead tr th.split-navy-orange {
	background-image: 
	linear-gradient(to bottom right, #2c394c 50%, transparent 0),
	linear-gradient(to bottom right, transparent 50%, #fe9d15 0);
}
.clone {
	position:absolute;
	top:0;
	left:0;
	pointer-events:none;
}
.clone th, .clone td {
	visibility:hidden
}
.clone td, .clone th {
	border-color:transparent
}
.clone tbody th {
	visibility:visible;
	font-weight: 600;
}
.clone .fixed-side {
	border:1px solid #5D6D7E;
	background:#fff;
	visibility:visible;
}
.clone thead{background:transparent;}

.key-questions {
	display: flex; 
	flex-direction: column;
	margin: 10px 40px;
	font-size: .9em;
	font-weight: 600;
	line-height: 1.25;
	align-items: stretch;
	align-content: stretch;	
	gap: 3px;
}

.key-questions > div, .key-questions > h3 {
	display: flex; 
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	align-content: center;
}

.key-questions > div > p, .key-questions > div > h3 {
	flex: 0 0 auto; 
	padding: 12px;
	margin-bottom: 16px;
}

.key-questions > div > p:nth-of-type(1) {
	width: 47%;
	border: 1px solid #2c394c;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}
.key-questions > div > p:nth-of-type(2) {
	width: 52%;
	font-style: italic;
	color: #fff;
	padding: 12px;
	padding-bottom: 14px;
	border: 1px solid #2c394c;
	background-color: #2c394c;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}
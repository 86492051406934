/*----------------------------------------------------*/
/*------------------[PAGE FURNITURE]------------------*/
/*----------------------------------------------------*/
body {
  position: relative; 
}

section {
  padding-top: $font-size-base * 3.382352941;
  padding-bottom: $font-size-base * 2.058823529; 
}






//.alert-myalert {
//  @include alert-variant(#60667d, #1d1d1d, #f4fdff);
//}

//@each $color, $value in $theme-colors {
//  .alert-#{$color} {
//    box-shadow: 3px 3px theme-color-level($color, -3);
//  }
//}


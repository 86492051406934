/*----------------------------------------------------*/
/*------------[TEAM & TEAM MEMBER SECTION]------------*/
/*----------------------------------------------------*/
/* Team Section
   ===================================================*/
#team h2 {
  color: #ffffff;
  margin-bottom: 60px; }

/* Thumbnail overrides
Note: added caption class to make headshots/captions
more portable (not just on main team grid)
===================================================*/
#team .thumbnail {
  text-align: center;
  background-color: transparent;
  color: #ffffff;
  border: none; 
  padding: .5rem;
  margin: .5rem 0;}

#team h3, #team p,
#team .caption h3 {
  color: #ffffff;
  line-height:  24px; }

#team h3, #team .caption h3  {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase; }

@media screen and (max-width: 1200px) {
    #team h3.text-nowrap {
        white-space: normal !important;
    }
}

#team p.title {
  font-size: 15px;
  font-weight: 400;
  font-style: italic; }

#team img {
  display: block;
  margin: 0 auto 1rem;
}

#team a.thumbnail:link, #team a.thumbnail:visited {
  color: #ffffff;
  text-decoration: none; }

#team a.thumbnail:hover,
#team a.thumbnail:focus,
#team a.thumbnail:active {
  color: #ffffff;
  text-decoration: none;
  outline: 0 solid transparent;
  /* remove border around link */ }

/* black and white hover effect. */
#team .thumbnail img.tinted {
  -webkit-transition: all 1s ease;
  -o-transition: all .1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

#team .thumbnail:hover img.tinted {
  -webkit-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
  /* W3C */
  filter: grayscale(100%);
  -webkit-filter: url("gray.svg#grayscale");
          filter: url("gray.svg#grayscale");
  -webkit-filter: gray;
          filter: gray;
  /*  IE6-9 */ }

  /* Team Member Section
===================================================*/
#team-member {
  padding: 20px 0 60px 0; }

#team-member h3, #team-member h1 {
  font-size: 23px;
  font-weight: 700;
  line-height: 26px;
  margin: -4px 0 16px 0;
  text-transform: uppercase;
  color: #ec952f;
  letter-spacing: 0; }

#team-member .title {
  font-size: 17px;
  line-height: 26px;
  color: #445363;
  font-weight: 400;
  font-style: italic;
  text-transform: none !important; }

#team-member p {
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 10px;
  color: #445363; }

#team-member a {
  font-weight: 700;
  border-bottom: 2px solid #5bb7b2; }

#team-member a:link, #team-member a:visited {
  color: inherit;
  text-decoration: none; }

#team-member a:hover, #team-member a:active {
  text-decoration: none;
  color: #5bb7b2; }

#team-member .eml {
  margin-top: 25px;
  font-size: 17px; }
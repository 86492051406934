/*----------------------------------------------------*/
/*----------------------[FOOTER]----------------------*/
/*----------------------------------------------------*/


div#address p, #footer .phone {
  color: #ffffff;
  font-size: 19px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 6px; }



#bluestar-flower-symbol-container {
  width: auto;
  max-width: 100px;
  padding: 0;
  margin: 0; }

#starflower-symbol, #starflower-symbol .petal {
  fill: white; }

#linkedin-symbol-container {
  padding: 0;
  margin: 0;
  text-align: left; }

#linkedin-symbol-container svg {
  width: 46px; }

.footerright {
  text-align: right; }

.phone {
  margin-top: 9px; }

.copyright {
  margin-top: 6em;
  font-size: 14px;
  color: #f3f3f3; }

#footer a[href^="tel"] {
  color: inherit; 
  text-decoration: none; 
}

#footer ul.sitelinks {
  list-style-type: none;
  margin: 0;
  padding: 0; }

#footer ul.sitelinks li {
  font-size: 17px;
  line-height: 28px;
  margin: 0 0 6px 0;
  padding: 0; }

#footer ul.sitelinks li#linkedin-symbol-container {
  margin: 28px 0 0 -3px; }

#footer .sitelinks a:link, #footer .sitelinks a:visited {
  color: #ffffff;
  text-decoration: none; }

#footer .sitelinks a:hover,
#footer .sitelinks a:active,
#footer .sitelinks a:focus,
#footer a[href^="tel"]:hover,
#footer a[href^="tel"]:active,
#footer a[href^="tel"]:focus, {
  background-color: transparent;
  color: #000000;
}

#linkedin-symbol-container:hover #linkedin,
#bluestar-flower-symbol-container:hover .petal {
    fill: #0b3947;
}

@media screen and (max-width: 991px) {
  #address, #linkedin-symbol-container, ul.sitelinks, .copyright {
    text-align: center !important; }
  #bluestar-flower-symbol-container {
    margin: 0 auto 2em; }
  #footer ul.sitelinks {
    margin-top: 2em; } 
  #footer a[href^="tel"] {
    text-decoration: underline; 
  }
}